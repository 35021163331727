import { destroy, get, post, put } from './api'

export const getParticipatingParties = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/participatingParties`);
}

export const createParticipatingParty = async (dialogDefinitionId, props) => {
  return await post(`dialogDefinitions/${dialogDefinitionId}/participatingParties`, props);
}

export const updateParticipatingParty = async (dialogDefinitionId, id, props) => {
  return await put(`dialogDefinitions/${dialogDefinitionId}/participatingParties/${id}`, props);
}

export const deleteParticipatingParty = async (dialogDefinitionId, id) => {
  return await destroy(`dialogDefinitions/${dialogDefinitionId}/participatingParties/${id}`);
}