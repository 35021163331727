import { Link } from "react-router-dom";
import { useClassNames } from "../../hooks/useClassNames";
import { Card } from '@metaforcelabs/metaforce-core';

const ListGroupItems = ({ title, subtitle, items }) => {

  return (
    <div className=" my-10">
      {title && <h2 className="text-lg font-medium text-gray-900" aria-hidden="true">{title}</h2>}
      {subtitle && <p className="mt-1 text-sm text-gray-500" aria-hidden="true">{subtitle}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-8 gap-5 lg:gap-10 mt-10">
        {items.filter(x => !x.disabled).map((item, itemIdx) => (
          item.isExternal ? (
            <a href={item.href} className="col-span-1 md:col-span-2 h-full">
              <Item key={itemIdx} item={item}/>
            </a>
          ) : (
            <Link to={item?.href} className="col-span-1 md:col-span-2 h-full">
              <Item key={itemIdx} item={item}/>
            </Link>
          )
        ))}
      </div>
    </div>
  );
};

const Item = ({item}) => {
  const {classNames} = useClassNames();

  return (
    <Card className="col-span-1 md:col-span-2 h-full">
      <article
        className={classNames(
          'p-0 relative flex items-center md:items-start md:flex-col h-full',
        )}
      >
        <header className="flex lg:h-1/4">
          <span
            className={classNames(
              item?.disabled ? "bg-gray-400" : item?.iconColor,
              "inline-flex items-center justify-center h-12 w-12 rounded-lg"
            )}
          >
            <item.icon className="h-6 w-6 text-white" aria-hidden="true"/>
          </span>
        </header>
        <footer className="lg:h-3/4 text-sm font-medium text-gray-900">
          <div className="text-lg ml-5 md:ml-0 md:mt-5">

            <span className={`${item?.disabled ? "cursor-not-allowed" : ""} absolute inset-0`} aria-hidden="true"/>
            {item.name}
          </div>

          <p className="text-md font-normal text-gray-500 mt-2 hidden lg:block" aria-hidden="true">{item?.description}</p>
        </footer>
      </article>
    </Card>
  )
}

export default ListGroupItems;