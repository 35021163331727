import { useState, useEffect, useRef, useMemo } from "react";
import { getParticipatingParties } from "../../../api/participatingParties";
import { useToastAction } from "../../../hooks/useToastAction";
import PartyInfo from "./partyInfo";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { sendDialogValues, createDialogValues } from "../../../api/dialogValues";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { templateLanguages, processTypes, securityType, notificationEventType } from "../../../utils/constants";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { getDialogDefinition } from "../../../api/smartFormsIntegration";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { config } from "../../../utils/config";
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { NotificationDropdown } from "../../../components/NotificationDropdown";
import { getActiveEmailNotificationTemplates, getActiveSmsNotificationTemplates, getNotificationEventTypes } from "../../../api/notificationTemplates";
import { getNotificationsByDialogDefinitionId } from "../../../api/dialogNotifications";

export default function Send() {
  const [allTemplateLanguages, setAllTemplateLanguages] = useState(templateLanguages);

  const selectData = [
    {
      value: "sender",
      name: "Sender",
    },
    {
      value: "receiver",
      name: "Receiver",
    },
  ];

  const userData = [
    {
      name: "Petter Dybdahl",
      email: "email@email.com",
      phone: "+47 987 65 432",
    },
    {
      name: "Olle Hanson",
      email: "email@email.com",
      phone: "+47 987 65 432",
    },
    {
      name: "Per Persson",
      email: "email@email.com",
      phone: "+47 987 65 432",
    },
    {
      name: "Lasse Otteson",
      email: "email@email.com",
      phone: "+47 987 65 432",
    },
  ];

  const sendAction = useToastAction();
  const createAndOpenAction = useToastAction();
  const loadAction = useToastAction();
  const { oidcUser } = useReactOidc();
  const [search, setSearch] = useState([]);
  const [populate, setPopulate] = useState(selectData[0].value);
  const [users, setUsers] = useState([]);
  const [participatingParties, setParticipatingParties] = useState([]);
  const [isMultiStepProcess, setIsMultiStepProcess] = useState(false);
  const [smartFormName, setSmartFormName] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationEventTypes, setNotificationEventTypes] = useState([]);
  const [isSmsOpen, setIsSmsOpen] = useState(false);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState({});
  const [smsTemplate, setSmsTemplate] = useState({});
  const [sendSms, setSendSms] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);

  const history = useHistory();
  const sendSmsRef = useRef();
  sendSmsRef.current = false;

  const { oidcUser: { profile: { name, email } } } = useReactOidc();

  const { dialogKey } = useParams();

  useEffect(() => {
    load();
    loadNotificationData();
  }, []);

  const load = () => {
    sendAction.execute(async () => {
      setUsers(userData);
      const participatingParties = await getParticipatingParties(dialogKey)
        const mapped = participatingParties.map(p => {
          const { name, ...rest } = p
          return {
            ...rest,
            name: name,
          }
        }
      )

      const dialogDefinition = await getDialogDefinition(dialogKey)
      setIsMultiStepProcess(dialogDefinition?.process === processTypes.multiStep);
      setSmartFormName(dialogDefinition?.name);

      setParticipatingParties(mapped)
      setIsLoaded(true);
    }, "Failed to load info");
  };

  const loadNotificationData = async () => {
    loadAction.execute(async () => {
      const [emailTemp, smsTemp, notifications, eventTypes] = await Promise.all([getActiveEmailNotificationTemplates(), getActiveSmsNotificationTemplates(), getNotificationsByDialogDefinitionId(dialogKey), getNotificationEventTypes()]);
      
      let email;
      let sms;
      if(notifications?.emailNotifications.length > 0){
        email = {...emailTemp.find(email => email.id === notifications.emailNotifications[0]?.notificationTemplateId)}
      } else {
        email = {...emailTemp.find(email => email.notificationEventType === notificationEventType.notification && email.isDefaultForType)}
      }
      
      if(notifications?.smsNotifications.length > 0){
        sms = {...smsTemp.find(email => email.id === notifications.smsNotification[0]?.notificationTemplateId)}
      } else {
        sms = {...smsTemp.find(sms => sms.notificationEventType === notificationEventType.notification && sms.isDefaultForType)}
      }

      //Email
      const  { languageContents: languageContentsEmail, ...emailTemplateRest } = email;
      emailTemplateRest.subjects = extractContent(languageContentsEmail, "subject");
      emailTemplateRest.bodies = extractContent(languageContentsEmail, "body");
      emailTemplateRest.supportedLanguages = Object.keys(emailTemplateRest?.bodies);
      setEmailTemplate(emailTemplateRest);

      //SMS
      const { languageContents: languageContentsSms, ...smsTemplateRest } = sms;
      smsTemplateRest.messages = extractContent(languageContentsSms, "message");
      smsTemplateRest.supportedLanguages = Object.keys(smsTemplateRest?.messages);
      setSmsTemplate(smsTemplateRest);

      const languagesSupportedFromEmail = Object.keys(emailTemplateRest.bodies);
      setAllTemplateLanguages(templateLanguages.filter(language => languagesSupportedFromEmail.includes(language.value)));
      setNotificationEventTypes(eventTypes);
    }, "Failed to load notifications")
  }

  const createAndOpen = (e) => {
    e.preventDefault();

    createAndOpenAction.execute(async () => {
      const dialogValues = await createDialogValues(dialogKey);
      window.open(`${config.smartFormsUiBaseUrl}/dialog/${dialogKey}/form/${dialogValues.id}`, "_blank")
    }, "Failed to create preview")
  }

  const handleSearch = (e) => {
    const searchBy = e.target.value;

    if (searchBy && searchBy !== "") {
      setSearch([
        ...users.filter((m) =>
          m.name.toLowerCase().includes(searchBy.toLowerCase())
        ),
      ]);
    } else {
      setSearch([]);
    }
  };

  const onSendSmsClick = (evt) => {
    const value = evt.target.checked;
    sendSmsRef.current = value;
    setSendSms(value)
    setAllTemplateLanguages(filterLanguages(value, sendEmail))
  }

  const onSendEmailClick = (evt) => {
    const value = evt.target.checked;
    setSendEmail(value);
    setAllTemplateLanguages(filterLanguages(sendSms, value));
  }

  const onSelectChange = (value) => {
    setPopulate(value);
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const extractContent = (languageContents, type) => {
    return templateLanguages.reduce((prev, curr) => {
      const { value } = curr;
      let content;
      if (type === "subject") {
        content = languageContents.find(lc => lc.languageCode === value)?.subject || "";
      } else if (type === "body") {
        content = languageContents.find(lc => lc.languageCode === value)?.body || "";
      } else if (type === "message") {
        content = languageContents.find(lc => lc.languageCode === value)?.message || "";
      }
      return content !== "" ? { ...prev, [value]: content } : prev
    }, {});
  };

  const filterLanguages = (sendSms, sendEmail) => {
    const filteredLanguages = templateLanguages.filter(language => {
      if (sendSms && sendEmail) {
        return emailTemplate.supportedLanguages.includes(language.value) && smsTemplate.supportedLanguages.includes(language.value)
      } else if (sendSms && !sendEmail) {
        return smsTemplate.supportedLanguages.includes(language.value)
      } else if (!sendSms && sendEmail) {
        return emailTemplate.supportedLanguages.includes(language.value)
      }
      return false;
    });
  
    return filteredLanguages;
  };

  return (
    <>
      {
        isLoaded && 
        <Formik
          initialValues={{
            receiverName: '',
            receiverEmail: '',
            receiverPhone: '',
            senderName: name,
            senderEmail: email,
            senderPhoneNumber: '',
            dialogValuesSecurity: securityType.none,
            privateSecret: oidcUser.profile.name,
            sendEmail: true,
            sendSms: false,
            privateSecret: name,
            isMultiStepProcess: isMultiStepProcess,
            parties: isMultiStepProcess ? participatingParties : [],
            language: allTemplateLanguages[0]?.value
          }}
          validationSchema={() => {
            return yup.object({
              receiverPhone: yup.string().when(["isMultiStepProcess", "sendSms"], {
                is: (isMultiStepProcess, sendSms) => isMultiStepProcess === false && sendSms === true,
                then: yup.string().test("validate-phone-number", function (value) {
                  const { path, createError } = this;
                  const isValid = value ? isPossiblePhoneNumber(value) : false
                  if(!isValid){
                    return createError({ path, message: 'Receiver phone number must be valid.' })
                  }

                  return true;
              }).required("Required"),
              }),   
              parties: yup.array().of(
                yup.object().shape({
                  name: yup.string().required("Required"),
                  email: yup.string().email("Must be an email").required("Required"),
                  phone: yup.string().when("sendSms", {
                    is: (sendSms) => sendSmsRef.current === true, // need to use ref because inner yup arrays can't get values of outter object
                    then: yup.string().test("validate-phone-number", function (value) {

                      const { path, createError } = this;
                      const isValid = value ? isPossiblePhoneNumber(value) : false
                      if(!isValid){
                        return createError({ path, message: 'Phone number must be valid.' })
                      }
    
                      return true;
                  }).required("Required")
                  })
                  //phone: yup.string().required("Required"),
                })
              ),
            });
          }}
          onSubmit={async (values, actions) => {
            delete values.isMultiStepProcess;

            sendAction.execute(
              async () => {
                const payload = {
                  dialogValuesSecurity: 1,
                  dialogDefinitionId: dialogKey,
                  sendEmail: true,//values.sendEmail,
                  privateSecret: values.privateSecret,
                  sendSms: values.sendSms,
                  receiverEmail: values.receiverEmail,
                  receiverName: values.receiverName,
                  receiverPhone: values.receiverPhone,
                  participatingParties: values.parties.map((p, i) => {
                    return {
                      participatingPartyId: participatingParties[i].id,
                      email: p.email,
                      name: p.name,
                      phone: p.phone,
                    };
                  }),
                  languageCode: values.language
                };

                await sendDialogValues(payload)
                actions.setSubmitting(false);
                history.push('/send');
              },
              "Failed to send form",
              "Form has been sent!",
              "...Sending"
            );
          }}
        >
          {(props) => (
            <Form onKeyDown={onKeyDown}>
              <section className="pb-5 border-b border-gray-200 flex justify-between items-center">
                <h1 className="text-3xl font-bold">Select roles for {smartFormName} form</h1>

                <div className="space-x-4">
                  {/* <button
                    type="button"
                    className="mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400  sm:mt-0 sm:text-sm"
                    onClick={() => console.log("click")}
                  >
                    Preview
                  </button> */}
                  <button
                    className="mt-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                    onClick={createAndOpen}
                  >
                    Create and open
                  </button>
                  <FormikSubmitButton
                    className="mt-3 w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                    formikProps={props}
                    text="Send"
                  />
                </div>
              </section>

              {/* <section className="mt-4 text-gray-600 pb-5 border-b border-gray-200 font-semibold">
                <div className="flex justify-start items-center">
                  <span>Populate</span>
                  <div className="w-25 mx-4">
                    <Select
                      name="process"
                      selectedValue={populate}
                      onChange={onSelectChange}
                      options={selectData}
                    />
                  </div>
                  <span>fields by recent contacts</span>
                </div>
                <div className="flex w-full my-8">
                  {users?.map((user) => (
                    <div className="flex flex-row justify-start items-center mr-12">
                      <div
                        key={user.name}
                        className="flex flex-shrink-0 bg-gray-200 h-10 w-10 rounded-full justify-center items-center mr-3"
                      >
                        <UserIcon className="h-7 w-7 text-black" />
                      </div>
                      <p className="text-black">{user.name}</p>
                    </div>
                  ))}
                </div>
              </section> */}
              <section className="grid sm:grid-cols-2 gap-10 mt-10">
                {
                  isMultiStepProcess && participatingParties?.map((party, index) => (
                  <PartyInfo
                    handleSearch={handleSearch}
                    title={party.name}
                    party={party}
                    i={index}
                    formikProps={props}
                  />
                  ))
                }

                {
                    !isMultiStepProcess &&
                    <article className="col-span-1">
                    <h3 className="text-2xl font-semibold">Receiver</h3>
                    <div className="mt-4">
                      <FormikTextInput
                        label={`Name`}
                        name={`receiverName`}
                        required={true}
                        horizontal
                        formikProps={props}
                      />
                    </div>
                    <div className="mt-4">
                      <FormikTextInput
                        label={`Email`}
                        name={`receiverEmail`}
                        required={true}
                        horizontal
                        formikProps={props}
                      />
                    </div>
                    <div className="mt-4">
                      <FormikTextInput
                        label={`Phone`}
                        name={`receiverPhone`}
                        horizontal
                        formikProps={props}
                      />
                    </div>
                  </article>
                    // <>
                    //   <FormikTextInput
                    //     label="Receiver name"
                    //     name="receiverName"
                    //     required={true}
                    //     formikProps={props}
                    //   />
                    //   <div className="">
                    //     <FormikTextInput
                    //       label="Receiver email"
                    //       name="receiverEmail"
                    //       required={true}
                    //       formikProps={props}
                    //     />
                    //   </div>
                    //   <div className="mt-4">
                    //     <FormikTextInput
                    //       label="Receiver phone number"
                    //       name="receiverPhoneNumber"
                    //       formikProps={props}
                    //     />
                    //   </div>
                    // </>
                }
              </section>

              <div className="grid sm:grid-cols-2 gap-10">
                <div className="mt-8 pt-8 border-t border-gray-200">
                  <div className="flex">
                    <FormikCheckbox
                      label="Send email"
                      name="sendEmail"
                      formikProps={props}
                      onClick={onSendEmailClick}
                      disabled={!props.values.sendSms}
                    />
                    <div className="ml-8">
                      <FormikCheckbox
                        label="Send SMS"
                        name="sendSms"
                        formikProps={props}
                        onClick={onSendSmsClick}
                        disabled={!props.values.sendEmail}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <FormikTextInput
                      label="Private Secret (can be replaced with your own)"
                      name="privateSecret"
                      required={true}
                      formikProps={props}
                    />
                  </div>
                  <div className="mt-4">
                    <FormikSelectInput
                      label="Language"
                      name="language"
                      options={allTemplateLanguages}
                      formikProps={props}
                    />
                  </div>
                  {
                    props.values.sendSms &&
                    <div className="mt-4">
                      <NotificationDropdown
                        label="SMS message"
                        isOpen={isSmsOpen}
                        setIsOpen={setIsSmsOpen}
                        template={smsTemplate}
                        selectedLanguage={props.values.language}
                        notificationEventTypes={notificationEventTypes}
                        isSms
                      />
                    </div>
                  }
                  {
                    props.values.sendEmail &&
                    <div className="mt-4">
                      <NotificationDropdown
                        label="Email message"
                        isOpen={isEmailOpen}
                        setIsOpen={setIsEmailOpen}
                        template={emailTemplate}
                        selectedLanguage={props.values.language}
                        notificationEventTypes={notificationEventTypes}
                      />
                    </div>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  );
}
