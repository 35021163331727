import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash';

const MAX_HISTORY_LENGTH = 150

export const dialogDefinitionSlice = createSlice({
  name: 'dialogDefinitions',
  initialState: {
    current: null,
    history: []
  },
  reducers: {
    set: (state, action) => {
      const { dialogDefinition, isInitialLoad } = action.payload;

      const oldCurrent = _.cloneDeep(state.current)
      state.current = dialogDefinition

      if (isInitialLoad) {
        state.history = []
      }

      if (oldCurrent && !isInitialLoad) {
        state.history.push(oldCurrent)
      }

      if (state.history.length > MAX_HISTORY_LENGTH) {
        state.history.shift()
      }
    },
    undo: (state) => {
      if (state.history.length > 0) {
        state.current = state.history.pop()
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  set: setDialogDefinition,
  undo: undoDialogDefinition,
} = dialogDefinitionSlice.actions

export default dialogDefinitionSlice.reducer