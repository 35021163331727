import React, { useState } from "react";
import {useClassNames} from "../../../hooks/useClassNames";
import DigitalSigning from "./DigitalSigning";
import Smartforms from "./Smartforms";

export default function Process() {
  const [tabs, setTabs] = useState([
    {
      name: 'Smartform',
      componentName: 'Smartform',
      href: '',
      current: false,
    }, {
      name: 'Digital Signing',
      componentName: 'DigitalSigning',
      href: '',
      current: true,
    }
  ]);

  const { classNames } = useClassNames();

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();

    setTabs((state) => (
      state.map(s => {
        if (s.name === tab.name) {
          s.current = true;
        } else {
          s.current = false;
        }

        return s;
      })
    ))
  };

  return (
    <>
      <div className="mt-10 pb-5 mb-10 border-b border-gray-200 sm:pb-0">
        <h1 className="text-3xl font-bold">Processes</h1>

        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>

            <select
              id="current-tab"
              name="current-tab"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={(e) => handleOnTabItemClick(tab, e)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {tabs.find((tab) => tab.current).componentName === 'DigitalSigning' ? (
        <DigitalSigning />
      ) : (
        <Smartforms />
      )}
    </>
  );
}
