import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useToastAction } from "../../../hooks/useToastAction";
import { ArrowRightIcon, SearchIcon } from "@heroicons/react/outline";
import { Select } from "../../../components/Form/Select";
import { rollbackDialogValues, deleteDialogValues, getDialogValuesWithStepsByDefinitionId, resendDialogValues } from "../../../api/dialogValues";
import { searchArray } from "../../../utils/array";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import MenuContextList from "../../../components/MenuContextList";
import * as SolidIcons from "@heroicons/react/solid";
import * as OutlineIcons from "@heroicons/react/outline";
import { getActiveDialogDefinitions } from "../../../api/dialogDefinition";
import * as Constants from "../../../utils/constants";
import { SkeletonLoader } from "../../../components/Table";
import { Badge, useTableActions } from '@metaforcelabs/metaforce-core';
import { Table } from '@metaforcelabs/metaforce-core';
import { config } from '../../../utils/config';
import { DialogValuesStatusBadge } from "../../../components/DialogValuesStatusBadge";
const dummyLoadData = [{}, {}, {}, {}, {}];

export default function ProcessDetails() {
  const { dialogKey, step } = useParams();
  const checkbox = useRef()

  const [processModel, setProcessModel] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false)
  const [checked, setChecked] = useState(false)
  const [records, setRecords] = useState(dummyLoadData);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);
  const [stepsOptions, setStepsOptions] = useState([]);
  const [stepFilterSelected, setStepFilterSelected] = useState();
  const [issuedByOptions, setIssuedByOptions] = useState([]);
  const [issuedByFilterSelected, setIssuedByFilterSelected] = useState();
  const [selectedItem, setSelectedItem] = useState([]);

  const tableActions = useTableActions(recordsToShow, 5)// "createdDate", "desc");

  const loadDialogValuesDetailsAction = useToastAction();
  const loadDialogDefinitionsAction = useToastAction();
  const deleteDialogValueAction = useToastAction();
  const rollbackDialogValueAction = useToastAction();

  const resendAction = useToastAction();
  const [dialogDefinitionOptions, setDialogDefinitionOptions] = useState([]);

  function toggleAll() {
    setSelectedItem(checked || indeterminate ? [] : records)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  useEffect(() => {
    loadDialogValuesDetails();
    loadDialogDefinitions();
  }, []);

  useEffect(() => {
    performFilter();
  }, [issuedByFilterSelected, stepFilterSelected]);

  const performFilter = () => {
    let recordsFiltered = records;

    recordsFiltered = [...searchArray(recordsFiltered, issuedByFilterSelected?.toLowerCase(), [a => a.dialogValue?.sentByName?.toLowerCase()])];
    recordsFiltered = [...searchArray(recordsFiltered, stepFilterSelected?.toLowerCase(), [a => a.currentStep?.name?.toLowerCase()])];

    setRecordsToShow(recordsFiltered);
  }

  const loadDialogDefinitions = async () => {
    loadDialogDefinitionsAction.execute(async () => {
      const records = await getActiveDialogDefinitions()

      setDialogDefinitionOptions(records.map(record => ({
        value: record.id,
        name: record.name
      })))

    }, "Failed to load")
  }

  const loadDialogValuesDetails = async () => {
    loadDialogValuesDetailsAction.execute(async () => {
      const model = await getDialogValuesWithStepsByDefinitionId(dialogKey)

      createIssuedByOptions(model.items);
      createStepOptions(model.items);

      setProcessModel(model)
      setRecords(model.items);
      setRecordsToShow(model.items);
    }, "Failed to load")
  }

  const createIssuedByOptions = (items) => {
    let distinctSentBy = [...new Set(items.map(record => record.dialogValue?.sentByName))];

    setIssuedByOptions(distinctSentBy.map(item => ({
      value: item,
      name: item
    })
    ));
  }

  const createStepOptions = (items) => {
    let distinctSentBy = [...new Set(items.map(record => record.currentStep?.name))];

    setStepsOptions(distinctSentBy.map(item => ({
      value: item,
      name: item
    })
    ));

    if (step) {
      // let selectedStep = items[0].
      // setStepFilterSelected("Step 1");
    }
  }

  const handleSearch = (e) => {
    const searchBy = e.target.value;

    let recordsFiltered = records;

    recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.currentParty?.toLowerCase()])];
    // recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.dialogValue?.sentByName?.toLowerCase()])];
    // recordsFiltered = [...searchArray(recordsFiltered, searchBy?.toLowerCase(), [a => a.currentParty?.toLowerCase()])];

    setRecordsToShow(recordsFiltered);
  };

  const rollback = (dialogValuesId) => {
    rollbackDialogValueAction.execute(async () => {
      await rollbackDialogValues(dialogValuesId)
      loadDialogValuesDetails();
    }, "Failed to rollback")
  }

  const rollbackSelected = () => {
    rollbackDialogValueAction.execute(async () => {
      for (let i = 0; i < selectedItem.length; i++) {
        await rollbackDialogValues(selectedItem[i].dialogValue.id)
      }
      loadDialogValuesDetails();
    }, "Failed to rollback")
  }

  const resendSelected = () => {
    resendAction.execute(async () => {
      for (let i = 0; i < selectedItem.length; i++) {
        await resendDialogValues(selectedItem[i].dialogValue.id)
      }
      loadDialogValuesDetails();
    }, "Failed to resend")
  }

  const resend = (dialogValuesId) => {
    resendAction.execute(async () => {
      await resendDialogValues(dialogValuesId)
      loadDialogValuesDetails();
    }, "Failed to resend")
  }

  const handleFormChange = (value) => {
    window.location.href = `/view/smartforms/${value}`;
  }

  const deleteSelected = async () => {
    deleteDialogValueAction.execute(async () => {
      for (let i = 0; i < selectedItem.length; i++) {
        await deleteDialogValues(selectedItem[i].dialogValue.id)
      }
      loadDialogValuesDetails();
    }, "Failed to delete")
  }

  const handleDelete = async (dialogValuesId) => {
    deleteDialogValueAction.execute(async () => {
      await deleteDialogValues(dialogValuesId)
      loadDialogValuesDetails();
      return true;
    }, "Failed to delete")
  }

  const getDialogValueCurrentStepUrl = (record) => {
    if(record?.dialogValue?.process === 'Multi-Step') {
      return `${config.smartFormsUiBaseUrl}/dialog/${processModel?.dialogDefinitionId}/form/${record?.id}/steps/${record?.currentStep?.id}`;
    }
    else return `${config.smartFormsUiBaseUrl}/dialog/${processModel?.dialogDefinitionId}/form/${record?.id}`;
  }

  return (
    <>
      <div className="mt-10 pb-5 border-b border-gray-200 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Processes</h1>
      </div>
      <div className="flex justify-between mt-5 mb-5 pb-5 border-b border-gray-200">
        <div className="flex gap-6">
          <div className="w-72">
            <Select
              label="Form"
              options={dialogDefinitionOptions}
              onChange={handleFormChange}
              selectedValue={dialogKey}
            />
          </div>
          <div className="w-72">
            <Select
              label="Issued by"
              name="process"
              options={issuedByOptions}
              onChange={setIssuedByFilterSelected}
              value={issuedByFilterSelected}
              defaultOptionText="All"
            />
          </div>
          {
            processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
            <div className="w-72">
              <Select
                label="Steps"
                name="process"
                options={stepsOptions}
                onChange={setStepFilterSelected}
                selectedValue={stepFilterSelected}
                value={stepFilterSelected}
                defaultOptionText="All"
              />
            </div>
          }
        </div>
        <div className="w-72 mt-6">
          <label
            htmlFor="desktop-search-candidate"
            className="sr-only"
          >
            Filter by text search...
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder="Search..."
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-4 flex justify-between">
        <div className="relative flex items-start pl-6">
          <div className="flex items-center h-full">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              ref={checkbox}
              checked={checked}
              onChange={toggleAll}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="flex items-center h-full ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              Select all submissions
            </label>
          </div>
        </div>

        <div className="pb-3 flex justify-end gap-3">
          <button
            type="button"
            disabled={selectedItem?.length <= 0}
            onClick={() => rollbackSelected()}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.RefreshIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Rollback selected
          </button>
          <button
            type="button"
            disabled={selectedItem?.length <= 0}
            onClick={() => resendSelected()}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Send reminder to selected
          </button>
          <button
            type="button"
            onClick={() => deleteSelected()}
            disabled={selectedItem?.length <= 0}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OutlineIcons.TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Delete selected
          </button>
        </div>
      </div>

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">

          <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">

                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() =>
                      tableActions.orderBy("dialogDefinitionName")
                    }
                  >
                    Belongs to form
                    {tableActions.getSortIcon("dialogDefinitionName")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("currentParty")
                    }
                  >
                    Current party
                    {tableActions.getSortIcon("currentParty")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("dialogValue.sentByName")
                    }
                  >
                    Issued by
                    {tableActions.getSortIcon("dialogValue.sentByName")}
                  </th>
                  {
                    processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                      onClick={() =>
                        tableActions.orderBy("dialogValue.isComplete")
                      }
                    >
                      Step
                      {tableActions.getSortIcon("dialogValue.isComplete")}
                    </th>
                  }

                  {
                    processModel.dialogDefinitionProcessType !== Constants.processTypes.multiStep &&
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    >
                      Status
                    </th>
                  }
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("createdDate")
                    }
                  >
                    Created
                    {tableActions.getSortIcon("createdDate")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                    onClick={() =>
                      tableActions.orderBy("updatedDate")
                    }
                  >
                    Updated
                    {tableActions.getSortIcon("updatedDate")}
                  </th>
                  <th
                    scope="col"
                    className="relative px-6 py-3 w-20"
                  >
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  tableActions.pageData?.map(record => {
                    return (
                      <tr key={record.Id}>
                        <td
                          className="px-6 py-8 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                        >
                          <SkeletonLoader element={record}>
                            {selectedItem.includes(record) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                              value={record.id}
                              checked={selectedItem.includes(record)}
                              onChange={(e) =>
                                setSelectedItem(
                                  e.target.checked
                                    ? [...selectedItem, record]
                                    : selectedItem.filter((p) => p !== record)
                                )
                              }
                            />
                          </SkeletonLoader>
                        </td>
                        <td
                          className="px-6 py-8 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                        >
                          <SkeletonLoader element={record}>
                            {processModel.dialogDefinitionName}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {record.currentParty || '-'}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {record.dialogValue?.sentByName || 'N/A'}
                          </SkeletonLoader>
                        </td>
                        {
                          processModel.dialogDefinitionProcessType === Constants.processTypes.multiStep &&
                          <td className="py-8 whitespace-nowrap text-sm text-center relative">
                            <SkeletonLoader element={record}>
                              <DialogValuesStatusBadge record={record} isMultistep={true} />
                            </SkeletonLoader>
                          </td>
                        }

                        {
                          processModel.dialogDefinitionProcessType !== Constants.processTypes.multiStep &&
                          <td className="py-8 whitespace-nowrap text-sm text-center relative">
                            <SkeletonLoader element={record}>
                              <DialogValuesStatusBadge record={record} isMultistep={false} />
                            </SkeletonLoader>
                          </td>
                        }
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {stringToLocaleDateTimeString(record.createdDate)}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-sm text-center relative">
                          <SkeletonLoader element={record}>
                            {stringToLocaleDateTimeString(record.updatedDate)}
                          </SkeletonLoader>
                        </td>
                        <td className="py-8 whitespace-nowrap text-right text-sm font-medium absolute">
                          <SkeletonLoader element={record}>
                            {
                              <MenuContextList
                                deleteType={"Dialog Values"}
                                actions={[
                                  {
                                    name: "View",
                                    lineAfter: true,
                                    icon: ArrowRightIcon,
                                    href: getDialogValueCurrentStepUrl(record),
                                    useUrlOutsideOfThisReactRouterInstance: true
                                  },
                                  {
                                    name: "Rollback",
                                    icon: SolidIcons.RefreshIcon,
                                    onClick: () => rollback(record.id)
                                    // onClick: () => onDelete(element.id)
                                  },
                                  {
                                    name: "Send reminder",
                                    icon: SolidIcons.MailIcon,
                                    onClick: () => resend(record.id)
                                  },
                                  {
                                    name: "Delete",
                                    icon: SolidIcons.TrashIcon,
                                    onClick: () => handleDelete(record.id)
                                  },
                                ]}
                              />
                            }
                          </SkeletonLoader>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <Table.Pagination tableActions={tableActions} />
          </div>
        </div>
      </div>
    </>
  );
}
