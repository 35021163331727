import { ChevronRightIcon } from '@heroicons/react/solid'
import { DocumentTextIcon } from '@heroicons/react/outline'
import { stringToLocaleDateTimeString } from '../../../utils/date';

export default function ShowData({ allData, whiteBackground = false, size}) {
  const widthClass = () => {
    switch (size) {
      case "medium":
        return "w-5/12";
      case "large":
        return 'w-6/12';
      default:
        return "w-6/12";
    }
  }

  return (
    <div className={`${whiteBackground ? 'bg-white shadow' : ''} overflow-hidden sm:rounded-md w-full md:w-8/12 lg:w-6/12 mt-4`}>
      <ul className="divide-y divide-gray-200">
        { allData?.map((data) => (
          <li key={data.id}>
            <a href={`send/${data?.id}`} className="block hover:bg-gray-50 cursor-pointer">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex flex-shrink-0 bg-gray-200 h-10 w-10 rounded-full justify-center items-center">
                    <DocumentTextIcon className="h-7 w-7 text-black"/>
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className={`text-sm font-medium text-black truncate ${!data?.id ? "w-3/5 skeleton-loader" : ""}`}>{data?.name}</p>
                      <p className={`mt-2 flex items-center text-sm text-gray-500 ${!data?.id ? "w-3/5 skeleton-loader" : ""}`}>
                        { data?.updatedDate && 
                          <span className="truncate">Updated {stringToLocaleDateTimeString(data.updatedDate)}</span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className='flex justify-center items-center'>
                  <p className='text-md mb-1 mr-2'>Select</p>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
