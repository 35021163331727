import { SearchIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import { getActiveDialogDefinitions } from "../../../api/smartFormsIntegration";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useToastAction } from "../../../hooks/useToastAction";
import ShowData from "./showData";

export default function Send() {
  const dummyLoadData = [
    {}, {}, {}, {}, {}
  ];

  const sendAction = useToastAction();
  const [allData, setAllData] = useState([{}]);
  const [search, setSearch] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const pages = [{ name: "Send", href: "/send", current: true }];

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    sendAction.execute(async () => {
      const info = await getActiveDialogDefinitions();
      setAllData(info);
      setIsLoaded(true)
    }, "Failed to load info");
  };

  const handleSearch = (e) => {
    const searchBy = e.target.value;

    if (searchBy && searchBy !== "") {
      setSearch([
        ...allData.filter((m) =>
          m.name.toLowerCase().includes(searchBy.toLowerCase())
        ),
      ]);
    } else {
      setSearch([]);
    }
  };

  let hasData = search?.length > 0;
  let recentForms = allData?.slice(0, 5);

  return (
    <section>
      {/* <Breadcrumbs pages={pages} /> */}
      <h1 className="text-3xl font-bold border-b border-gray-40 pb-4 mb-10">
        Select Smartform
      </h1>
      <div className="flex justify-start flex-col mt-4">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-700">
          Find form
        </h3>
        <div className="relative rounded-md mt-2">
          <SearchIcon className="absolute top-0 left-0 bottom-0 h-5 m-auto mx-2 text-gray-400" />
          <input
            type="text"
            className={`focus:ring-gray-400 focus:border-gray-400 block w-full md:w-8/12 lg:w-6/12 sm:text-sm border-gray-300 rounded-md pl-10`}
            onChange={handleSearch}
            placeholder="Form name"
          />
        </div>

        { hasData ? (
          <ShowData allData={search} size="large" whiteBackground />
          ) : (
          <>
            <h3 className="ml-1 mt-10 text-md leading-6 font-medium text-gray-600">
              Or select from recent forms
            </h3>
              <ShowData allData={isLoaded ? recentForms : dummyLoadData} size="medium" whiteBackground />
          </>
        )}
      </div>
    </section>
  );
}
