import { get } from './api'

const controllerName = "NotificationTemplates"

export const getNotificationEventTypes = async () => {
    return await get(`${controllerName}/NotificationEventTypes`);
}

export const getEmailNotificationTemplate = async (templateId) => {
    return await get(`${controllerName}/Email/${templateId}`);
}
export const getSmsNotificationTemplate = async (templateId) => {
    return await get(`${controllerName}/Sms/${templateId} `);
}

export const getActiveEmailNotificationTemplates = async () => {
    return await get(`${controllerName}/Email/Active`);
}

export const getActiveSmsNotificationTemplates = async () => {
    return await get(`${controllerName}/Sms/Active`);
}