import React, {useEffect, useState} from "react";
import {Select} from "../../../components/Form/Select";
import {SearchIcon} from "@heroicons/react/outline";
import {SkeletonLoader} from "../../../components/Table";
import {NavLink} from "react-router-dom";
import {getInProgressReport} from "../../../api/dialogValues";
import {searchArray} from "../../../utils/array";
import {useTableActions} from '@metaforcelabs/metaforce-core';
import {useToastAction} from "../../../hooks/useToastAction";

const dummyLoadData = [{}, {}, {}, {}, {}];

const Smartforms = () => {
  const [records, setRecords] = useState(dummyLoadData);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);
  const [dialogStatusFilterSelected, setDialogStatusFilterSelected] = useState(0);

  const tableActions = useTableActions(recordsToShow);
  const loadAction = useToastAction();

  const dialogStatusOptions = [
    {
      value: 0,
      name: "Active",
    },
    {
      value: 1,
      name: "Inactive",
    }
  ];

  const load = async () => {
    loadAction.execute(async () => {
      const records = await getInProgressReport(dialogStatusFilterSelected);

      records.forEach(record => {
        // Add explicit properties for each step for sorting
        if (record.stepInProgressCounts[0]) {
          record.step0 = record.stepInProgressCounts[0]
        } else if (record.stepInProgressCounts[1]) {
          record.step1 = record.stepInProgressCounts[1]
        }
        else if (record.stepInProgressCounts[2]) {
          record.step2 = record.stepInProgressCounts[2]
        }
        else if (record.stepInProgressCounts[3]) {
          record.step3 = record.stepInProgressCounts[3]
        }
        else if (record.stepInProgressCounts[4]) {
          record.step4 = record.stepInProgressCounts[4]
        }
      });
      setRecords(records);
      setRecordsToShow(records);
    }, "Failed to load")
  }

  const handleSearch = (e) => {
    const searchBy = e.target.value;
    setRecordsToShow([...searchArray(records, searchBy?.toLowerCase(), [a => a.dialogDefinitionName.toLowerCase()])])
  };

  const renderStep = (viewModel, stepNumber) => {
    if (stepNumber > viewModel.maxStepOrder) {
      return null
    }
    const isFirst = stepNumber === 0;
    const isLastStep = stepNumber === viewModel.maxStepOrder
    return (
      <NavLink to={`/view/smartforms/${viewModel.dialogDefinitionId}/${stepNumber}`} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
        <div className={`bg-indigo-100 ${!isLastStep ? "text-indigo-500"
          : "px-6 bg-indigo-600 text-white rounded-xl"} inset-0 m-auto absolute ${isFirst ? "rounded-l-xl" : ""} h-5`}
             style={{ width: '104%' }}
        >
          {viewModel?.stepInProgressCounts && viewModel?.stepInProgressCounts[stepNumber]}
        </div>
      </NavLink>
    )
  };

  useEffect(() => {
    load();
  }, [dialogStatusFilterSelected]);

  return (
    <>
      <div className="flex justify-between mt-5 mb-10 pb-5 border-b border-gray-200">
        <div className="w-72">
          <Select
            label="Apply filter"
            name="process"
            options={dialogStatusOptions}
            onChange={setDialogStatusFilterSelected}
            value={dialogStatusFilterSelected}
          />
        </div>
        <div className="w-72 mt-6">
          <label
            htmlFor="desktop-search-candidate"
            className="sr-only"
          >
            Filter by text search...
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder="Search..."
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
          <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() =>
                    tableActions.orderBy("dialogDefinitionName")
                  }
                >
                  Smartform
                  {tableActions.getSortIcon("dialogDefinitionName")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step0")
                  }
                >
                  Start
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step0")
                  }
                >
                  Step 1{tableActions.getSortIcon("step0")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step1")
                  }
                >
                  Step 2{tableActions.getSortIcon("step1")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step2")
                  }
                >
                  Step 3{tableActions.getSortIcon("step2")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step3")
                  }
                >
                  Step 4{tableActions.getSortIcon("step3")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("step4")
                  }
                >
                  Step 5{tableActions.getSortIcon("step4")}
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer text-center"
                  onClick={() =>
                    tableActions.orderBy("inProgressTotal")
                  }
                >
                  Total count
                  {tableActions.getSortIcon("inProgressTotal")}
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {
                tableActions.tableData?.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className="px-6 py-8 whitespace-nowrap text-sm font-medium text-gray-900 relative"
                        style={{ minWidth: "200px" }}
                      >
                        <SkeletonLoader element={record}>
                          <NavLink to={`/view/smartforms/${record.dialogDefinitionId}`} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                            {record.dialogDefinitionName}
                          </NavLink>
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 0)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 1)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 2)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 3)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 4)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {renderStep(record, 5)}
                        </SkeletonLoader>
                      </td>
                      <td className="py-8 whitespace-nowrap text-sm text-center relative">
                        <SkeletonLoader element={record}>
                          {record.inProgressTotal}
                        </SkeletonLoader>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
};

export default Smartforms;