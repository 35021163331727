import {UsersIcon, CheckCircleIcon, XCircleIcon, MailIcon} from "@heroicons/react/solid";
import {stringToLocaleDateTimeString} from "../../utils/date";
import {Card} from '@metaforcelabs/metaforce-core';

export default function StackedList({title, subtitle, items}) {
  return (
    <div className="overflow-hidden sm:rounded-md grid grid-cols-1">
      <div className="mb-6">
        {title && <h2 className="col-start-1 text-lg font-medium text-gray-900">{title}</h2>}
        {subtitle && <p className="col-start-1 mt-1 text-sm text-gray-500">{subtitle}</p>}
      </div>

      <div className="p-0 shadow-sm rounded-lg border border-gray-200 bg-white lg:w-2/3 xl:w-1/2 min-h-2 ">
        <ul className="col-start-1 divide-y divide-gray-200">
          {items.map((item) => (
            <li key={item?.id}>
              <a href={item?.href} className="block">
                <div className="flex items-center p-4">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p
                          className={`text-sm font-medium text-gray-500 truncate ${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                          {item?.dialogDefinitionName}
                        </p>
                        <p
                          className={`mt-2 flex items-center text-sm text-gray-500 ${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                          {
                            item?.id &&
                            <>
                              {
                                item?.receiverEmail ?
                                  (
                                    <>
                                      <MailIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">{item.receiverEmail || ""}</span>
                                    </>
                                  )
                                  :
                                  (
                                    <>
                                      <UsersIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">Multistep form</span>
                                    </>
                                  )
                              }
                            </>
                          }
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className={`text-sm text-gray-500 ${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                            {item?.updatedDate && "Updated on"}
                            {item?.updatedDate &&
                              <time
                                dateTime={item?.updatedDate}>{` ${stringToLocaleDateTimeString(item.updatedDate)}`}</time>
                            }
                          </p>
                          <p
                            className={`mt-2 flex items-center text-sm text-gray-500 ${!item?.id ? "w-3/5 skeleton-loader" : ""}`}>
                            {
                              item?.id &&
                              <>
                                {item?.isComplete ?
                                  <CheckCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                  /> :
                                  <XCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                }
                                {item?.isComplete ? "Completed" : "Not completed"}
                              </>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}