import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import  { DotsVerticalIcon } from '@heroicons/react/solid'
import { useClassNames } from '../../hooks/useClassNames';
import Modal from '../Modal/index'

export default function MenuContextList({actions, element, deleteType = element}) {
    const classNames = useClassNames();
    const [showModal, setShowModal] = useState(false)
    const deleteAction = actions.find(item => item.name === "Delete");
    
  return (
    <>
    <Menu as="div" className="ml-3 relative inline-block text-left">
        <div>
            <Menu.Button className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
        </div>
    <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
    >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div className="py-1">
        {actions.map((action, i) => (
          <Menu.Item key={i}>
          {({ active }) => (
                action.href ? (
                    !action.useUrlOutsideOfThisReactRouterInstance ? (
                        <NavLink 
                            to={action.href}
                            className={classNames.classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'w-full flex justify-between px-4 py-2 text-sm')}>
                            {action.name}
                        </NavLink>
                    )
                    :
                    <a
                    href={action.href}
                    target="_blank'"
                    rel="noreferrer"
                    className={classNames.classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'w-full flex justify-between px-4 py-2 text-sm'
                    )}
                    >
                    {action.name}
                    </a>
                ) 
                :
                <button
                    key={`action-${i}`}
                    type="button"
                    className={classNames.classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'w-full flex justify-between px-4 py-2 text-sm'
                    )}
                    disabled={action.disabled}
                    onClick={action.name === 'Delete' ? () => setShowModal(true) : action.onClick}
                    to={action.href}
                >
                    {action.name}
                </button>
          )}
          </Menu.Item>
        ))}
        </div>
        </Menu.Items>
    </Transition>
    </Menu>
    <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false) }
        size={'medium'}
        title={`Do you want to delete the ${deleteType}?`}
    >
        <div className="mt-5 sm:mt-6">  
            <button
                type="button"
                className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-pink text-base font-medium text-white hover:bg-brand-pink-hover sm:text-sm"
                onClick={ (event) => deleteAction.onClick(event) }
            >
                Delete
            </button>
            <button
                type="button"
                className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => setShowModal(false) }
            >
                Cancel
            </button> 
        </div>
    </Modal>
</>
  );
}