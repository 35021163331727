import React, { useEffect, useState } from 'react';
import { WhiteLabelProvider } from '@metaforcelabs/metaforce-core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  RecoilRoot,
} from 'recoil';
import { AuthenticationProvider, oidcLog, WebStorageStateStore, useReactOidc } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import {config, oidcConfig} from './utils/config';
import AdminLayout from "./layouts/AdminLayout"
import Oidc from "./screens/Oidc";
import { Toaster } from './toaster';
import { OidcSecure } from '@axa-fr/react-oidc-context'
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader } from './api/api'
import MissingLicense from './screens/Errors/MissingLicense';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useToastAction } from './hooks/useToastAction';
import Send from './screens/Admin/Send';
import Home from './screens/Admin/Home'
import SendFlow from './screens/Admin/Send/sendFlow';
import Document from './screens/Admin/Document';
import {useLicense} from "./hooks/useLicense";
import * as Constants from './utils/constants'
import Process from './screens/Admin/Process';
import ProcessDetails from './screens/Admin/ProcessDetails';

function App() {
  return (
    <div className="app h-screen">
      <RecoilRoot>
        <Router>
          <AuthenticationProvider
            configuration={oidcConfig}
            loggerLevel={oidcLog.ERROR}
            isEnabled={true}
            UserStore={WebStorageStateStore}
            callbackComponentOverride={Oidc}
            notAuthenticated={() => { return (<h1>Not Authenticated.</h1>) }}
            notAuthorized={() => { return (<h1>Not Authorized.</h1>) }}
            authenticating={Oidc}
          >
            <Switch>
              <Route exact={true} path="/unauthorized" component={Unauthorized} />
              <Route exact={true} path="/missing-license" component={MissingLicense} />
              <Route exact={true} path="/not-found" component={NotFound} />

              <OidcSecure>
                <SetAuthToken>
                  <SetOidcRoutesContext>
                    {/* Admin routes */}
                    <WhiteLabelProvider configuration={{
                      centerpointApiUrl: config.centerpointBaseUrl
                    }}>
                      <AdminLayoutRoute exact={true} path="/" nav="home" component={Home} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/home" nav="home" component={Home} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/send" nav="send" component={Send} licenseId={Constants.licenses.smartForms} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/send/:dialogKey" nav="sendDialogkey" component={SendFlow} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/document" nav="document" component={Document} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/view/smartforms" nav="smartforms" component={Process} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/view/smartforms/:dialogKey" nav="smartformsDialogKey" component={ProcessDetails} featureEnabled={true} />
                      <AdminLayoutRoute exact={true} path="/view/smartforms/:dialogKey/:step" nav="smartformsDialogKeyStep" component={ProcessDetails} featureEnabled={true} />
                    </WhiteLabelProvider>
                  </SetOidcRoutesContext>
                </SetAuthToken>
              </OidcSecure>
            </Switch>
          </AuthenticationProvider>
        </Router>
        <Toaster />
      </RecoilRoot>
    </div>
  )
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  licenseId,
  ...rest
}) {

  const { hasLicense } = useLicense();

  return (

    <Route {...rest} render={(props) =>
      <ProtectedRoute hasLicense={hasLicense(licenseId)}  featureEnabled={featureEnabled}>
        <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
          <Component {...props} />
        </AdminLayout>
      </ProtectedRoute>
    } />
  )
}

const ProtectedRoute = ({ featureEnabled, hasLicense, children }) => {
  return featureEnabled && hasLicense ? (
    <>
      {children}
    </>
  ) :
    (<Redirect to={'/'} />)
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
    }, "Failed to load user data");
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return !loadAction.isExecuting && (
    <OidcRoutesContext.Provider
      value={{
        userProfile,
        customerInfo
      }}>
      {children}
    </OidcRoutesContext.Provider>
  )
}

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header 
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token)

  useEffect(() => {
    if (!events) return
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events])

  const addUserSignedOut = () => {
    const userManager = getUserManager()
    userManager.removeUser();
    window.location.reload(false);
  }

  return (
    <>
      {children}
    </>
  );
}

export default App;
