import DateTimeParser from '../../../../utils/DateTimeParser';

export const PROVIDERS = {
  1: 'Signicat',
  2: 'Verified',
  3: 'Scrive'
};

export const ORDER_STATUS = {
  created: 1,
  inProgress: 5,
  completed: 10,
  download: 20,
  canceled: 70,
  rejected: 50,
  error: 99
};

export const SIGN_STATUS = {
  waiting: 1,
  completed: 3,
  rejected: 4
};

export const formInitialValues = {
  dateFrom: DateTimeParser.subtractYears(1),
  dateTo: DateTimeParser.toLocaleDateString(new Date()),
  signature: '',
  inProgress: true,
  completed: true,
  error: true
};
