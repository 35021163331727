import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { PlaceholderInput } from "@metaforcelabs/metaforce-core";
import { useReactIconFactory } from "../../hooks/useReactIconFactory";

export const NotificationDropdown = ({
  isOpen,
  setIsOpen,
  label,
  template,
  selectedLanguage,
  notificationEventTypes,
  isSms = false,
}) => {
  const { createReactIcon } = useReactIconFactory();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getPlaceholdersForType = (notificationEventType) => {
    const placeholders = notificationEventTypes.find(
      (x) => x.value === notificationEventType
    )?.placeholders;
    return (
      placeholders?.map(({ iconKey, ...placeholder }) => {
        const icon = createReactIcon(iconKey);
        return { ...placeholder, icon: icon };
      }) || []
    );
  };

  return (
    <div className="option mb-4 rounded-md w-full">
      <div
        className="flex justify-between items-center cursor-pointer hover:bg-gray-200 py-2"
        onClick={toggleOpen}
      >
        <span className="text-sm font-medium text-gray-700">{label}</span>
        {isOpen ? <ChevronUpIcon width={15} /> : <ChevronDownIcon width={15} />}
      </div>
      {isOpen && (
        <div className="expandable">
          {!isSms && (
            <div className="flex flex-col sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Subject
              </label>
              <div className="mt-1 sm:mt-0 relative">
                <PlaceholderInput
                  inputValue={template?.subjects[selectedLanguage] || ""}
                  className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400"
                  placeholderText="Subject"
                  placeholders={getPlaceholdersForType(template?.notificationEventType)}
                  name="subject"
                />
                <div
                  className="absolute inset-0 bg-transparent cursor-not-allowed"
                  style={{ pointerEvents: "all" }}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col sm:border-t sm:border-gray-200 sm:pt-5">
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Message
            </label>
            <div className="mt-1 sm:mt-0 w-full relative">
              <PlaceholderInput
                inputValue={
                  isSms
                    ? template?.messages[selectedLanguage] || ""
                    : template?.bodies[selectedLanguage] || ""
                }
                className="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md focus-within:ring-gray-400 focus-within:ring-1 focus-within:border-gray-400"
                placeholderText="Message"
                placeholders={getPlaceholdersForType(template?.notificationEventType)}
                name="body"
              />
              
              {
                /* Since PlaceholderInput doesnt have a disabled option I had to do this trick in order to make the user don't interact with the input.
                It will be nice to have a disabled option on the library
                */
              }
              <div
                className="absolute inset-0 bg-transparent cursor-not-allowed"
                style={{ pointerEvents: "all" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
