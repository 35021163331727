import React, { useContext, useEffect, useState } from "react";
import {  OidcRoutesContext } from "../contexts";
import * as Constants from '../utils/constants'

export const useLicense = () => {
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const hasLicense = (licenseId) => {
    return !!oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === licenseId);
  }
  
  return {
    hasSmartFormsLicense: () => {
      return hasLicense(Constants.licenses.smartForms);
    },
    hasDigitalSigningLicense: () => {
      return hasLicense(Constants.licenses.digitalSigning);
    },
    hasLicense: (licenseId) => {
      return !licenseId || hasLicense(licenseId);
    }
  }
}