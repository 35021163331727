import React, {useCallback, useEffect, useState} from "react";
import {useToastAction} from '@metaforcelabs/metaforce-core';
import {
  cancelOrder,
  getAllSignatureFiles,
  getCertifiedPdf,
  getOrderDetails, getProvidersSettingsApi, getSignatureFile,
  getSigningOrderList
} from "../../../../api/signingOrder";
import DateTimeParser from "../../../../utils/DateTimeParser";
import {Table, TableBody, TableColumn, TableHead, TableHeader, TableRow} from "../../../../components/Table";
import {ChevronDownIcon, ChevronRightIcon, DocumentDownloadIcon} from "@heroicons/react/outline";
import {convertToHumanFileSize} from "../../../../utils/file";
import {stringToLocaleDateTimeString} from "../../../../utils/date";
import MenuContextList from "../../../../components/MenuContextList";
import { ORDER_STATUS, PROVIDERS, SIGN_STATUS } from '../consts';
import {Badge} from "../../../../components/Badge";
import Button from "../../../../components/Button";
import Filter from "./Filter";
import toast from "react-hot-toast";

const getOrderStatus = (orderStatus) => {
  switch (orderStatus) {
    case ORDER_STATUS.created:
      return (
        <Badge
          type="info"
          text="In Progress"
        />
      ); //Created
    case ORDER_STATUS.inProgress:
      return (
        <Badge
          type="info"
          text="In Progress"
        />
      ); //In Progress
    case ORDER_STATUS.completed:
      return (
        <Badge
          type="success"
          text="Completed"
        />
      ); //Completed
    case ORDER_STATUS.download:
      return (
        <Badge
          type="success"
          text="Completed"
        />
      ); //Downloaded From Signing Provider
    case ORDER_STATUS.rejected:
      return (
        <Badge
          type="error"
          text="Rejected"
        />
      );
    case ORDER_STATUS.canceled:
      return (
        <Badge
          type="error"
          text="Canceled"
        />
      );
    case ORDER_STATUS.error:
      return (
        <Badge
          type="error"
          text="Error"
        />
      );
    default:
      return (
        <Badge
          type="default"
          text="Unknown Status"
        />
      );
  }
};

const getSignatoryStatus = (signStatus) => {
  switch (signStatus) {
    case 1:
      return (
        <Badge
          type="info"
          text="In Progress"
        />
      );
    case 2:
      return (
        <Badge
          type="info"
          text="Started"
        />
      );
    case 3:
      return (
        <Badge
          type="success"
          text="Completed"
        />
      );
    case 4:
      return (
        <Badge
          type="error"
          text="Rejected"
        />
      );
    case 5:
      return (
        <Badge
          type="warn"
          text="Expired"
        />
      );
    case 6:
      return (
        <Badge
          type="info"
          text="Forwarded"
        />
      );
    case 7:
      return (
        <Badge
          type="error"
          text="Canceled"
        />
      );
    case 0:
    default:
      return (
        <Badge
          type="default"
          text="Not Set"
        />
      );
  }
};

const DigitalSigning = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [providersSettings, setProvidersSettings] = useState();
  const [selectedRowId, setSelectedRowId] = useState('');
  const [filters, setFilters] = useState({
    dateFrom: DateTimeParser.subtractYears(1),
    dateTo: DateTimeParser.toLocaleDateString(new Date()),
    isShowMyFieldOnly: false,
    fileStatus: [],
    signature: ''
  });
  const [signatoriesId, setSignatoriesId] = useState('');

  const orderDetailsAction = useToastAction();
  const signingOrderListAction = useToastAction();
  const downloadSigningProveAction = useToastAction();
  const cancelOrderAction = useToastAction();
  const downloadSignedFilesAction = useToastAction();
  const downloadAllSigningProvesAction = useToastAction();
  const getProvidersSettingsAction = useToastAction();

  const getProvidersSettings = () =>
    getProvidersSettingsAction.execute(async () => {
      const result = await getProvidersSettingsApi();
      setProvidersSettings(result);
    }, 'Failed to get app settings.');

  const getOrderList = (data, setSubmitting) =>
    signingOrderListAction.execute(async () => {
      const signingOrderListResult = await getSigningOrderList(data);

      setOrderList(signingOrderListResult);

      if (setSubmitting) {
        setSubmitting(false);
      }
    }, '');

  const filterList = (order) => {
    if (filters.signature) {
      const foundInSignature = order.signatories.some((s) =>
        s.emailAddress.includes(filters.signature)
      );

      if (!foundInSignature) {
        return false;
      }
    }

    if (filters.fileStatus.length) {
      const foundFileStatus = filters.fileStatus.includes(order.orderStatus);

      if (!foundFileStatus) {
        return false;
      }
    }

    return true;
  };

  const getActionsList = (row) => {
    const actionsList = [
      {
        id: 1,
        name: 'Stop signing order',
        onClick: () => handleOrderCancel(row.id),
      },
      {
        id: 2,
        name: 'View Signed PDF',
        onClick: () => handleDownloadSignedFiles(row.id),
      },
      {
        id: 3,
        name: 'Download All files',
        onClick: () =>
          handleDownloadAllSigningProves(row.id),
      },
    ].filter((action) => {
      if (
        (row.orderStatus === ORDER_STATUS.canceled ||
          row.orderStatus === ORDER_STATUS.download ||
          row.orderStatus === ORDER_STATUS.completed) &&
        action.id === 1
      ) {
        return false;
      } else if (
        row.orderStatus !== ORDER_STATUS.download &&
        (action.id === 2 || action.id === 3)
      ) {
        return false;
      }
  
      return true;
    });
    return actionsList;
  }

  const handleOnRowClick = (row) => {
    setSelectedRowId((id) => (row.id === id ? '' : row.id));
  };

  const handleOrderCancel = useCallback(
    (id) => {
      cancelOrderAction.execute(async () => {
        const cancelOrderResult = await cancelOrder(id);

        if (cancelOrderResult) {
          toast.success('Order canceled successfully');
          getOrderList(filters);
        } else {
          toast.error('Failed to cancel order');
        }
      }, 'Failed to cancel order');
    },
    [selectedRowId]
  );

  const handleDownloadSignedFiles = (id) => {
    downloadSignedFilesAction.execute(async () => {
      await getCertifiedPdf(id);
    }, 'Failed to get certified pdf');
  };

  const handleDownloadAllSigningProves = (id) => {
    downloadAllSigningProvesAction.execute(
      async () => await getAllSignatureFiles(id),
      'Failed to get signature files'
    );
  };

  const handleDownloadSigningProve = useCallback((id) => {
    setSignatoriesId(id);
  }, []);

  const handleOnFilter = (values, setSubmitting) => {
    setSelectedRowId('');
    setFilters(values);
    getOrderList(values, setSubmitting);
  };

  useEffect(() => {
    if (!!signatoriesId) {
      downloadSigningProveAction.execute(
        async () => getSignatureFile(selectedRowId, signatoriesId),
        'Failed to get signature file'
      );
    }
  }, [signatoriesId]);

  useEffect(() => {
    if (!!selectedRowId) {
      orderDetailsAction.execute(async () => {
        const orderDetailsResult = await getOrderDetails(selectedRowId);
        setOrderDetails(orderDetailsResult);
      }, 'Failed to load data', );
    }
  }, [selectedRowId]);

  useEffect(() => {
    getProvidersSettings();
    getOrderList(filters);
  }, []);

  return (
    <>
      <Filter orderList={orderList} onSubmit={handleOnFilter} />

      <div className="relative">
        {orderList.filter(filterList).length > 0 && !signingOrderListAction.isExecuting && (
          <Table>
            <TableHead>
              <TableHeader></TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Number of files</TableHeader>
              <TableHeader>Size</TableHeader>
              <TableHeader>Created by</TableHeader>
              <TableHeader>Number of signatures</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Provider</TableHeader>
              <TableHeader>Status</TableHeader>
            </TableHead>
            <TableBody>
              {orderList.filter(filterList).map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    className={index % 2 === 0 ? undefined : "bg-gray-50"}>
                    <TableColumn
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                      onClick={() => handleOnRowClick(row)}>
                      {selectedRowId && selectedRowId === row.id ? (
                        <ChevronDownIcon className="w-5 h-5" />
                      ) : (
                        <ChevronRightIcon className="w-5 h-5" />
                      )}
                    </TableColumn>

                    <TableColumn className="px-6 py-4 text-sm text-gray-500">
                      {row.packageName}
                    </TableColumn>

                    <TableColumn>{row.numOfFiles}</TableColumn>

                    <TableColumn>
                      {convertToHumanFileSize(row.packageSize)}
                    </TableColumn>

                    <TableColumn>{row.createdBy}</TableColumn>

                    <TableColumn>{row.numOfSignatures}</TableColumn>

                    <TableColumn>
                      {stringToLocaleDateTimeString(row.updatedDate)}
                    </TableColumn>

                    <TableColumn>{PROVIDERS[row?.providerType]}</TableColumn>

                  <TableColumn>
                    <div className="flex items-center justify-between">

                      {getOrderStatus(row.orderStatus)}

                      {getActionsList(row).length > 0
                        && (<MenuContextList
                          container="body"
                          actions={getActionsList(row)}
                        />)}
                    </div>
                  </TableColumn>
                </TableRow>
                  {orderDetails &&
                    orderDetails.id === row.id &&
                    selectedRowId && (
                    <TableRow>
                      <TableColumn colSpan={9}>
                        {orderDetails.signatories
                          .sort((a, b) =>
                            a.signingSequence > b.signingSequence ? 1 : -1
                          )
                          .map((s) => (
                            <div
                              key={s.emailAddress}
                              className="flex items-center mb-2 pl-24">
                              <span>{s.emailAddress}</span>
                              {row.providerType &&
                                PROVIDERS[row.providerType] &&
                                providersSettings[PROVIDERS[row.providerType]]
                                  .showSignatorySigningProcessStatus && (
                                  <span className="ml-6">
                                    {getSignatoryStatus(s.signStatus)}
                                  </span>
                                )}

                              {s.signStatus === SIGN_STATUS.completed &&
                                row.providerType &&
                                PROVIDERS[row.providerType] &&
                                providersSettings[PROVIDERS[row.providerType]]
                                  .showDownloadSignaturesButtons && (
                                  <Button
                                    variant={Button.variants.icon}
                                    className="ml-4 pt-0 pr-0 pb-0 pl-0"
                                    onClick={() =>
                                      handleDownloadSigningProve(s.id)
                                    }>
                                    <DocumentDownloadIcon className="w-5 h-5 text-gray-400 hover:text-gray-600" />
                                  </Button>
                                )}
                            </div>
                          ))}
                      </TableColumn>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
        
        {orderList.filter(filterList).length === 0 && !signingOrderListAction.isExecuting && (
          <div className="w-full text-center">
            No orders found
          </div>
        )}
        
        {signingOrderListAction.isExecuting && (
          <div className="w-full text-center">
            Loading data...
          </div>
        )}
      </div>
    </>
  );
};

export default DigitalSigning;
