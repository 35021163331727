export const countryList = [
  {
    code: "en",
    name: "English",
    nativeName: "English",
  },
  {
    code: "no",
    name: "Norwegian",
    nativeName: "Norsk",
  },
  {
    code: "nn",
    name: "Ny Norsk",
    nativeName: "Ny Norsk",
  },
  {
    code: "sv",
    name: "Swedish",
    nativeName: "svenska",
  },
  {
    code: "fi",
    name: "Finnish",
    nativeName: "suomi",
  },
  {
    code: "da",
    name: "Danish",
    nativeName: "dansk",
  },
  {
    code: "is",
    name: "Icelandic",
    nativeName: "Íslenska",
  },
  {
    code: "es",
    name: "Spanish",
    nativeName: "español",
  },
  {
    code: "fr",
    name: "French",
    nativeName: "français",
  },
  {
    code: "it",
    name: "Italian",
    nativeName: "Italiano",
  },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "日本語 (にほんご／にっぽんご)",
  },
  {
    code: "pl",
    name: "Polish",
    nativeName: "polski",
  },
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
  },
].sort((a, b) => a.name.localeCompare(b.name));