import { BaseUrl, destroy, download, get, post, put } from './api'


export const getInProgressReport = async (dialogDefinitionStatus) => {
  return await get(`dialogValues/in-progress?status=${dialogDefinitionStatus}`);
}

export const sendDialogValues = async (payload) => {
  return await post('dialogValues/send', payload);
}

export const getLastDialogValues = async (id) => {
  return await get(`dialogValues/${id}/lastDialogValues`)
}

export const deleteDialogValues = async (id) => {
  return await destroy(`dialogValues/${id}`);
}

export const getDialogValuesWithStepsByDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/dialogValuesWithSteps`);
}

export const resendDialogValues = async (dialogValuesId) => {
  return await post(`dialogValues/${dialogValuesId}/resend`, {
    // empty
  });
}

export const rollbackDialogValues = async (id) => {
  return await post(`dialogValues/rollback/${id}`);
}

export const createDialogValues = async (dialogDefinitionId,) => {
  return await post('dialogValues', { dialogDefinitionId });
}