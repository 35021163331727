import {destroy, download, get, post} from './api';
import { config } from '../utils/config'

export const getSigningOrderList = async (data) => {
  return await post(`Integration/GetListOfOrders`, data, null, config.apiDigitalSigning);
};

export const getOrderDetails = async (orderId) => {
  return await get(`Integration/${orderId}`, config.apiDigitalSigning);
};

export const getProvidersSettingsApi = async (orderId) => {
  return await get(`Integration/GetProvidersSettings`, config.apiDigitalSigning);
};

export const getCertifiedPdf = async (orderId) => {
  return await download(`Integration/GetCertifiedPdf/${orderId}`, orderId, config.apiDigitalSigning);
};

export const getAllSignatureFiles = async (orderId) => {
  return await download(`Integration/GetAllSignatureFiles/${orderId}`, orderId, config.apiDigitalSigning);
};

export const getSignatureFile = async (selectedRowId, signatoryId) => {
  return await download(
    `Integration/GetSignatureFile/${selectedRowId}/${signatoryId}`,
    signatoryId,
    config.apiDigitalSigning
  );
};

export const cancelOrder = async (orderId) => {
  return await destroy(`Integration/CancelOrder/${orderId}`, null, config.apiDigitalSigning);
};
