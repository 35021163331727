import { SearchIcon } from "@heroicons/react/solid";
import { Field } from "formik";
import { FormikDate } from "../../../components/Form/Formik/FormikDate";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";

export default function PartyInfo({
  title,
  handleSearch,
  formikProps,
  i,
  party,
}) {
  return (
    <article className="col-span-1">
      <h3 className="text-2xl font-semibold">{title}</h3>
      {/* <div className="relative rounded-md mt-4 w-full">
        <SearchIcon className="absolute top-0 left-0 bottom-0 h-5 m-auto mx-2 text-gray-400" />
        <input
          type="text"
          className={`focus:ring-gray-400 focus:border-gray-400 block w-6/12 sm:text-sm border-gray-300 rounded-md pl-10 w-full`}
          onChange={handleSearch}
          placeholder={`Populate ${title.toLowerCase()} fields by contact search...`}
        />
      </div> */}
      {/* <Field
        type="hidden"
        value={party.id}
        name={`parties[${i}].id`}
      /> */}
      <div className="mt-4">
        <FormikTextInput
          label={`Name`}
          name={`parties[${i}].name`}
          required={true}
          horizontal
          formikProps={formikProps}
        />
      </div>
      <div className="mt-4">
        <FormikTextInput
          label={`Email`}
          name={`parties[${i}].email`}
          required={true}
          horizontal
          formikProps={formikProps}
        />
      </div>
      <div className="mt-4">
        <FormikTextInput
          label={`Phone`}
          name={`parties[${i}].phone`}
          horizontal
          formikProps={formikProps}
        />
      </div>
      <div className="mt-4">
        <FormikDate
          label="Birth date"
          name={`parties[${i}].birthDate`}
          horizontal
          formikProps={formikProps}
        />
      </div>
    </article>
  );
}
