import { get, put, post, destroy } from './api'

const controllerName = 'Integrations';

export const getDialogDefinition = async (id) => {
    return await get(`${controllerName}/${id}`);
  }

export const getActiveDialogDefinitions = async () => {
    return await get(`${controllerName}/active/list`);
}